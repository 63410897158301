import styled from 'styled-components';

export const Wrapper = styled.a`
  text-decoration: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  display: inline-block;
`;
