import styled from "styled-components";
import NextLink from "next/link";

const styles = `
  text-decoration: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
`;

export const ALink = styled.a`
  ${styles}
`;
