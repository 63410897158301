import * as React from "react";
import { prependStringIfNotExists } from "utils";
import NextLink from "next/link";
import { InternalLink as InternalLinkType } from "types/attheminute";

import * as Styled from "./InternalLink.styled";

interface InternalLinkProps {
  link?: InternalLinkType;
  children?: React.ReactNode;
  className?: string;
}

export default function InternalLink(props: InternalLinkProps) {
  const { children, link, className } = props;

  let rawUrl = link?.url;
  const url = prependStringIfNotExists("/", rawUrl || "");

  if (!link) return null;

  if (link.newTab) {
    return (
      <Styled.ALink href={url} target="_blank" className={className}>
        {children}
      </Styled.ALink>
    );
  } else {
    return (
      <NextLink href={url} passHref legacyBehavior>
        <Styled.ALink className={className}>{children}</Styled.ALink>
      </NextLink>
    );
  }
}
