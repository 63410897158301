import * as React from "react";
import { LinkTypes } from "types/attheminute";

import ExternalLink from "./ExternalLink";
import AnchorLink from "./AnchorLink";
import InternalLink from "./InternalLink";
import VideoLink from "./VideoLink";
import SubscribeLink from "./SubscribeLink";
import Button from "./Button";

const INTERNAL_LINK = "internalLink";
const EXTERNAL_LINK = "externalLink";
const ANCHOR_LINK = "anchorLink";
const VIDEO_LINK = "videoLink";
const SUBSCRIBE_LINK = "subscribeLink";
const BUTTON = "button";

const componentMap = {
  [INTERNAL_LINK]: InternalLink,
  [EXTERNAL_LINK]: ExternalLink,
  [ANCHOR_LINK]: AnchorLink,
  [VIDEO_LINK]: VideoLink,
  [SUBSCRIBE_LINK]: SubscribeLink,
  [BUTTON]: Button,
};

interface ActionLinkProps {
  dataTestId?: string;
  link?: LinkTypes;
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
}

export default function ActionLink(props: ActionLinkProps) {
  const { children, link, dataTestId = "action-link", className, ...rest } = props;

  if (!link || !link.component) return null;

  const WrapperComponent = componentMap[link.component];

  if (!WrapperComponent) return null;

  return (
    <WrapperComponent data-testid={dataTestId} className={className} link={link} {...rest}>
      {children}
    </WrapperComponent>
  );
}
