import * as React from 'react';
import PropTypes from 'prop-types';
import SubscribeModal from 'components/ui/SubscribeModal';
import useToggle from 'hooks/useToggle';

import * as Styled from './SubscribeLink.styled';

export default function SubscribeLink(props) {
  const { children, onClick, animate, ...rest } = props;
  const [isSubscribeModalOpen, toggleSubscribeModal] = useToggle(false);

  const handleClick = () => {
    toggleSubscribeModal();
    if (onClick) onClick();
  };

  return (
    <>
      <Styled.Wrapper
        aria-label="Toggle subscribe to email modal"
        onClick={handleClick}
        {...rest}
      >
        {children}
      </Styled.Wrapper>
      <SubscribeModal
        animate={animate}
        isOpen={isSubscribeModalOpen}
        onClose={toggleSubscribeModal}
      />
    </>
  );
}

SubscribeLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
