import { maxWidthStoryblokImage } from "utils/storyblok";
import * as Styled from "components/ui/SectionWrapper/SectionWrapper.styled";

interface DoubleImageProps {
  doubleImageLeft?: string;
  doubleImageRight?: string;
  flipLeftImage?: boolean;
  flipRightImage?: boolean;
  horizontalTranslationPercentage?: string;
}

export default function DoubleImage({
  doubleImageLeft,
  doubleImageRight,
  flipLeftImage = false,
  flipRightImage = false,
  horizontalTranslationPercentage = '0',
}: DoubleImageProps) {

  return (
    <>
      <Styled.LeftImage
        $flipBackgroundImage={flipLeftImage}
        src={maxWidthStoryblokImage(doubleImageLeft, "1024")}
        $translatePercentage={horizontalTranslationPercentage}
      />
      <Styled.RightImage
        $flipBackgroundImage={flipRightImage}
        src={maxWidthStoryblokImage(doubleImageRight, "1024")}
        $translatePercentage={horizontalTranslationPercentage}
      />
    </>
  );
}
