import * as React from "react";
import { prependStringIfNotExists } from "utils";

import * as Styled from "./AnchorLink.styled";
import { AnchorLink as AnchorLinkType } from "types/attheminute";

interface AnchorLinkProps {
  link?: AnchorLinkType;
  children?: React.ReactNode;
}

export default function AnchorLink(props: AnchorLinkProps) {
  const { link, children, ...rest } = props;

  const url = link ? prependStringIfNotExists("#", link.targetAnchorId) : "";

  return (
    <Styled.Wrapper href={url} {...rest}>
      {children}
    </Styled.Wrapper>
  );
}
