import styled from 'styled-components';
import screenSize from 'styles/mediaQueries';
import spacing from 'styles/spacing';

export const VideoWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: calc(100vh - 12.5rem);
  max-width: 56.25rem;
  max-height: 43.75rem;
  justify-content: center;
  align-items: center;

  ${screenSize.minTablet`
    width: calc(100vw - ${spacing.sm});
  `}
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  padding: 0.5rem;
  background: transparent;
  border: none;

  ${screenSize.minTablet`
    padding: 0 0.5rem;
  `}
`;
