import Modal from 'react-modal';
import PropTypes from 'prop-types';
import Cross24 from '../../../../public/images/icons/Cross24';
import { getYoutubeIdFromYoutubeUrl } from 'utils';

import { VideoWrapper, CloseButton } from './VideoModal.styled';

const modalStyles = {
  overlay: {
    zIndex: 19,
    backgroundColor: 'var(--color-modalOverlay)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: 'none',
    overflow: 'none',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    padding: 0,
    background: 'black',
  },
};

export default function VideoModal(props) {
  const {
    isOpen,
    onClose,
    youtubeUrl,
    dataTestIdContent,
    dataTestidCloseModal,
  } = props;

  if (!youtubeUrl) return null;

  const youtubeId = getYoutubeIdFromYoutubeUrl(youtubeUrl);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Video Modal"
      style={modalStyles}
    >
      <CloseButton
        aria-label="Close Button"
        onClick={onClose}
        data-testid={dataTestidCloseModal}
      >
        <Cross24 colour="white" />
      </CloseButton>
      {youtubeId && (
        <VideoWrapper data-testid={dataTestIdContent}>
          <iframe
            id="youtube_player"
            title={`YoutubeVideo-${youtubeId}`}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1&rel=0`}
            host="youtube"
            type="text/html"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </VideoWrapper>
      )}
    </Modal>
  );
}

VideoModal.defaultProps = {
  dataTestIdContent: 'video-modal-content',
  dataTestidCloseModal: 'video-modal-close',
  youtubeUrl: '',
};

VideoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  youtubeUrl: PropTypes.string,
  dataTestIdContent: PropTypes.string,
  dataTestidCloseModal: PropTypes.string,
};
