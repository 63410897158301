import * as React from 'react';
import VideoModal from 'components/ui/VideoModal';
import useToggle from 'hooks/useToggle';

import { VideoLink as VideoLinkType } from 'types/attheminute';

import * as Styled from './VideoLink.styled';

interface VideoLinkProps {
  link?: VideoLinkType;
  children?: React.ReactNode;
}

export default function VideoLink(props: VideoLinkProps) {
  const { children, link } = props;
  const [isVideoModalOpen, toggleVideoModal] = useToggle(false);

  if (!link) return null;

  return (
    <>
      <Styled.Wrapper
        aria-label="Toggle Video in modal"
        onClick={() => toggleVideoModal()}
      >
        {children}
      </Styled.Wrapper>
      <VideoModal
        isOpen={isVideoModalOpen}
        onClose={toggleVideoModal}
        youtubeUrl={link.youtubeUrl}
      />
    </>
  );
}
