import styled from "styled-components";
import screenSize from "styles/mediaQueries";
import spacing from "styles/spacing";
import layout from "styles/layout";
import NextImage from "next/legacy/image";
import {
  minDesktopXPadding,
  minTabletXPadding,
  mobileXPadding,
} from "../Layout";

interface SectionWrapperProps {
  largePadding?: boolean;
  removeBottomPadding?: boolean;
  removeTopPadding?: boolean;
  removeLeftPadding?: boolean;
  removeRightPadding?: boolean;
  backgroundOverride?: string;
  backgroundColour?: string;
  theme?: "light" | "dark";
  hasImage?: boolean;
  disableOverlay?: boolean;
}

const getBackgroundOrOverlay = ({
  backgroundOverride,
  backgroundColour,
  theme = "light",
  hasImage = false,
  disableOverlay = false,
}: SectionWrapperProps) => {
  let backgroundColorAttribute = "transparent";
  let backgroundAttribute = "";

  if (hasImage && theme && !disableOverlay) {
    const color =
      theme === "light"
        ? "var(--color-lightOverlay)"
        : "var(--color-darkOverlay)";
    backgroundColorAttribute = `${color}`;
  } else if (hasImage) {
    backgroundColorAttribute = "transparent";
  } else if (backgroundColour) {
    backgroundColorAttribute = backgroundColour;
  }

  if (backgroundOverride) {
    backgroundAttribute = backgroundOverride;
  }

  return `
    ${
      backgroundColorAttribute
        ? `background-color: ${backgroundColorAttribute};`
        : ""
    }
    ${backgroundAttribute ? `background: ${backgroundAttribute};` : ""}
  `;
};

interface ImageStyleProps {
  $containImage?: boolean;
  $flipBackgroundImage?: boolean;
}

export const Overlay = styled.div<SectionWrapperProps>`
  ${(props) => getBackgroundOrOverlay({ ...props })}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const imageStyles = (props: ImageStyleProps) => `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: ${props.$containImage ? "contain" : "cover"};
  padding: ${props.$containImage ? spacing.sm : "0"};
  object-position: 50% 50%;
  z-index: -1;
  border: none;
  ${(props: ImageStyleProps) =>
    props.$flipBackgroundImage ? "transform: scaleX(-1);" : ""};
  transition: all ease-in-out 200ms;
`;

export const SectionWrapper = styled.div<SectionWrapperProps>`
  z-index: 1;
  display: block;
  position: relative;
  width: 100%;
  padding: ${(props) =>
    props.largePadding
      ? `${spacing.xxl} ${mobileXPadding}`
      : `${mobileXPadding}`};
  ${(props) => props.removeBottomPadding && "padding-bottom: 0;"}
  ${(props) => props.removeTopPadding && "padding-top: 0;"}
  ${(props) => props.removeLeftPadding && "padding-left: 0;"}
  ${(props) => props.removeRightPadding && "padding-right: 0;"}

  ${screenSize.minTablet`
    padding: ${(props: SectionWrapperProps) =>
      props.largePadding
        ? `${spacing.xxxl} ${minTabletXPadding}`
        : `${minTabletXPadding}`};
    ${(props: SectionWrapperProps) =>
      props.removeBottomPadding && "padding-bottom: 0;"}
    ${(props: SectionWrapperProps) =>
      props.removeTopPadding && "padding-top: 0;"}
    ${(props: SectionWrapperProps) =>
      props.removeLeftPadding && "padding-left: 0;"}
    ${(props: SectionWrapperProps) =>
      props.removeRightPadding && "padding-right: 0;"}
  `}

  ${screenSize.minDesktop`
    padding: ${(props: SectionWrapperProps) =>
      props.largePadding
        ? `${spacing.xxxl} ${spacing.xl}`
        : `${minDesktopXPadding} ${minDesktopXPadding}`};
    ${(props: SectionWrapperProps) =>
      props.removeBottomPadding && "padding-bottom: 0;"}
    ${(props: SectionWrapperProps) =>
      props.removeTopPadding && "padding-top: 0;"}
    ${(props: SectionWrapperProps) =>
      props.removeLeftPadding && "padding-left: 0;"}
    ${(props: SectionWrapperProps) =>
      props.removeRightPadding && "padding-right: 0;"}
  `}
`;

export const BackgroundImageContainer = styled.div`
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const SingleImage = styled(NextImage)<ImageStyleProps>`
  object-fit: ${(props) => (props.$containImage ? "contain" : "cover")};
  z-index: -1;
  ${(props) => (props.$flipBackgroundImage ? "transform: scaleX(-1);" : "")};
`;

export const LazyImage = styled.img`
  ${(props: ImageStyleProps) => imageStyles(props)}
`;

interface DoubleImageProps extends ImageStyleProps {
  $translatePercentage?: string;
}

export const LeftImage = styled.img<DoubleImageProps>`
  ${(props) => imageStyles(props)}
  width: 50%;
  object-position: 65% 50%;
  ${screenSize.minTablet`
    object-position: 50% 50%;
  `}
  transform: ${(props) =>
    props.$flipBackgroundImage && "scaleX(-1)"} translateX(${(props) =>
    (props) =>
      props.$flipBackgroundImage ? "" : "-"}${(props) =>
    props.$translatePercentage}%);
`;

export const RightImage = styled.img<DoubleImageProps>`
  ${(props) => imageStyles(props)}
  width: 50%;
  object-position: 65% 50%;
  ${screenSize.minTablet`
    object-position: 50% 50%;
  `}
  right: 0;
  left: unset;
  transform: ${(props) => props.$flipBackgroundImage && "scaleX(-1)"}
    translateX(
      ${(props) => (props.$flipBackgroundImage ? "-" : "")}${(props) => (props.$translatePercentage ? props.$translatePercentage : "0")}%
    );
`;

export const Content = styled.div<{ contentWidth?: "expanded" | "regular" }>`
  max-width: ${(props) =>
    props.contentWidth === "expanded"
      ? layout.expandedContentMaxWidth
      : layout.contentMaxWidth};
  margin: 0 auto;
  z-index: 10;
  position: relative;
`;
