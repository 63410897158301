import { ColourName } from "styles/colours";
import SingleImage from "./SingleImage";
import DoubleImage from "./DoubleImage";
import * as Styled from "./SectionWrapper.styled";

export interface SectionOptions {
  flipBackgroundImage?: boolean;
  containImage?: boolean;
  disableOverlay?: boolean;
  theme?: "light" | "dark";
  backgroundColour?: ColourName;
  backgroundOverride?: string;
  backgroundImage?: string;
  largePadding?: boolean;
  removeBottomPadding?: boolean;
  removeTopPadding?: boolean;
  removeLeftPadding?: boolean;
  removeRightPadding?: boolean;
  doubleImageLeft?: string;
  doubleImageRight?: string;
  flipLeftImage?: boolean;
  flipRightImage?: boolean;
  horizontalTranslationPercentage?: string;
  isAboveTheFold?: boolean;
  contentWidth?: "expanded" | "regular";
}

interface SectionWrapperProps extends SectionOptions {
  className?: string;
  children?: React.ReactNode;
}

export default function SectionWrapper(props: SectionWrapperProps) {
  const {
    children,
    backgroundImage,
    backgroundColour,
    backgroundOverride,
    theme = "light",
    flipBackgroundImage = false,
    largePadding = false,
    removeBottomPadding = false,
    removeTopPadding = false,
    removeLeftPadding = false,
    removeRightPadding = false,
    disableOverlay = false,
    doubleImageLeft,
    doubleImageRight,
    contentWidth = "expanded",
    containImage = false,
    className,
    ...rest
  } = props;

  let imageState = "";

  if (doubleImageLeft && doubleImageRight) imageState = "DOUBLE_IMAGE";
  else if (backgroundImage) imageState = "SINGLE_IMAGE";

  return (
    <Styled.SectionWrapper
      hasImage={!!backgroundImage || (!!doubleImageLeft && !!doubleImageRight)}
      backgroundColour={
        backgroundColour ? `var(--color-${backgroundColour})` : ""
      }
      backgroundOverride={backgroundOverride}
      theme={theme}
      disableOverlay={disableOverlay}
      largePadding={largePadding}
      removeBottomPadding={removeBottomPadding}
      removeTopPadding={removeTopPadding}
      removeLeftPadding={removeLeftPadding}
      removeRightPadding={removeRightPadding}
      className={className}
      {...rest}
    >
      <Styled.Overlay
        backgroundOverride={backgroundOverride}
        backgroundColour={
          backgroundColour ? `var(--color-${backgroundColour})` : ""
        }
        theme={theme}
        hasImage={
          !!backgroundImage || (!!doubleImageLeft && !!doubleImageRight)
        }
        disableOverlay={disableOverlay}
      />
      <Styled.BackgroundImageContainer>
        {imageState === "SINGLE_IMAGE" && <SingleImage {...props} />}
        {imageState === "DOUBLE_IMAGE" && <DoubleImage {...props} />}
      </Styled.BackgroundImageContainer>
      <Styled.Content contentWidth={contentWidth}>{children}</Styled.Content>
    </Styled.SectionWrapper>
  );
}

// TODO: This is a translation step and can probably just happen at build time rather than at runtime
export const getSectionWrapperProps = (
  sectionOptions?: SectionOptions
): SectionOptions => {
  if (!sectionOptions) return {};

  const sectionWrapperProps = {
    flipBackgroundImage: sectionOptions.flipBackgroundImage,
    containImage: sectionOptions.containImage,
    disableOverlay: sectionOptions.disableOverlay,
    theme: sectionOptions.theme,
    backgroundColour: sectionOptions.backgroundColour,
    backgroundOverride: sectionOptions.backgroundOverride,
    backgroundImage: sectionOptions.backgroundImage,
    largePadding: sectionOptions.largePadding,
    removeBottomPadding: sectionOptions.removeBottomPadding,
    removeTopPadding: sectionOptions.removeTopPadding,
    removeLeftPadding: sectionOptions.removeLeftPadding,
    removeRightPadding: sectionOptions.removeRightPadding,
    doubleImageLeft: sectionOptions.doubleImageLeft,
    doubleImageRight: sectionOptions.doubleImageRight,
    flipLeftImage: sectionOptions.flipLeftImage,
    flipRightImage: sectionOptions.flipRightImage,
    horizontalTranslationPercentage:
      sectionOptions.horizontalTranslationPercentage,
    contentWidth: sectionOptions.contentWidth,
  };
  return sectionWrapperProps;
};
