import { maxWidthStoryblokImage, storyblokLoader } from "utils/storyblok";
import * as Styled from "components/ui/SectionWrapper/SectionWrapper.styled";

interface SingleImageProps {
  backgroundImage?: string;
  flipBackgroundImage?: boolean;
  containImage?: boolean;
  isAboveTheFold?: boolean;
}

export default function SingleImage({
  backgroundImage,
  flipBackgroundImage,
  containImage = false,
  isAboveTheFold = false,
}: SingleImageProps) {
  if (!backgroundImage) return null;
  const src = maxWidthStoryblokImage(backgroundImage, "1920");

  return (
    <Styled.SingleImage
      $flipBackgroundImage={flipBackgroundImage}
      $containImage={containImage}
      src={src}
      layout="fill"
      alt=""
      priority={isAboveTheFold}
      loader={storyblokLoader}
    />
  );
}
