import * as React from 'react';
import { ExternalLink as ExternalLinkType } from "types/attheminute";

import * as Styled from './ExternalLink.styled';


export interface ExternalLinkProps {
  link?: ExternalLinkType;
  children?: React.ReactNode;
}

export default function ExternalLink(props: ExternalLinkProps) {
  const { link, children, ...rest } = props;

  const targetValue = link && link.newTab ? '_blank' : '_self';
  const url = link && link.url ? link.url : '';

  const fullUrl = url.substring(0, 4) === 'http' ? url : `http://${url}`;

  return (
    <Styled.Wrapper href={fullUrl} target={targetValue} {...rest}>
      {children}
    </Styled.Wrapper>
  );
}
